<template>
	<div class="tab-container">
		<h2 class="title" v-if="false">推送设置</h2>
		<el-form ref="form" :model="form" label-position="left" v-if="false">
			<el-row :gutter="40">
				<el-col :span="8">
					<p class="label" style="line-height: 32px">推送模式：</p>
					<el-form-item v-show="isEdit">
						<el-radio-group v-model="tabData.pushMode">
							<el-radio :label="1">实时</el-radio>
							<el-radio :label="2">定时</el-radio>
						</el-radio-group>
					</el-form-item>
					<span v-show="!isEdit">
						{{ tabData.pushMode === 1 ? '实时' : '--' }}
					</span>
				</el-col>
			</el-row>
			<el-row :gutter="40" v-show="form.pushMode === -1">
				<el-col :span="8">
					<p class="label">
						定时推送
						<br />
						开始时间：
					</p>
					<el-form-item>
						<el-time-picker
							size="small"
							format="HH:mm"
							value-format="HH:mm"
							placeholder="HH:mm"
							v-model="form.delaySendStartTime"
							:picker-options="{ selectableRange: '00:00:00- 23:58:59' }"
						></el-time-picker>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<p class="label">
						定时推送
						<br />
						结束时间：
					</p>
					<el-form-item>
						<el-time-picker
							size="small"
							format="HH:mm"
							value-format="HH:mm"
							placeholder="HH:mm"
							v-model="form.delaySendEndTime"
							:picker-options="{ selectableRange: endTimeRange }"
						></el-time-picker>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<h2 class="title" style="font-size: 14px; margin-top: 16px; display: flex; justify-content: flex-end">
			<el-button class="btn-staff" type="primary" @click="visible = true" plain>选择员工</el-button>
		</h2>
		<gc-table class="table" :columns="columns" :table-data="tableData" :border="true"></gc-table>

		<!-- 选择员工弹框 -->
		<staff-dialog
			:show.sync="visible"
			:columns="columns"
			:push-channel="tabData.pushChannel"
			:select-data="tableData"
			:alarmConfigInfo="tabData"
			@update="handleUpdateTable"
		/>
		<!-- 二维码 -->
		<div class="mask" v-if="showQrcode">
			<div class="content">
				<i class="el-icon-close icon" @click="showQrcode = false"></i>
				<div class="qrcode" ref="qrcode"></div>
			</div>
		</div>
	</div>
</template>

<script>
import StaffDialog from './StaffDialog.vue'
import { apiQrcodeUrl } from '@/apis/organizeStaff.api'
import QRCode from 'qrcodejs2'

export default {
	name: 'TabContent',
	components: { StaffDialog },
	props: {
		tabData: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			form: {
				pushMode: '',
				delaySendStartTime: '',
				delaySendEndTime: '',
			},
			columns: [
				{ key: 'orgName', name: '所属部门' },
				{ key: 'staff', name: '员工名称' },
				{ key: 'role', name: '岗位' },
				{ key: 'phone', name: '手机号码' },
			],
			visible: false,
			showQrcode: false,
		}
	},
	computed: {
		isEdit() {
			return this.$attrs['is-edit'] || false
		},
		tableData() {
			return this.tabData?.receiveStaffList || []
		},
		// 推送结束时间必须大于推送开始时间
		endTimeRange() {
			const startTime = this.form.delaySendStartTime
			if (!startTime) return '00:00:00 - 23:59:59'
			const [hour, min] = startTime.split(':')
			const addZero = v => (v < 10 ? '0' + v : v)
			if (min < 59) return `${addZero(+hour)}:${addZero(+min + 1)}:00 - 23:59:59`
			if (hour < 23) return `${addZero(+hour + 1)}:00:00 - 23:59:59`
			return '00:00:00 - 23:59:59'
		},
	},
	methods: {
		handleCreateQRcode(row) {
			if (row) {
				this.$message.warning('暂不支持，敬请期待')
				return
			}
			apiQrcodeUrl({
				staffId: row.id,
			}).then(({ url }) => {
				this.showQrcode = true
				this.$nextTick(() => {
					new QRCode(this.$refs.qrcode, {
						text: url,
						width: 360,
						height: 360,
						colorDark: '#000000', // 生成二维码的深色部分
						colorLight: '#ffffff', // 生成二维码的浅色部分
						correctLevel: 3, // 纠错等级
					})
				})
			})
		},
		handleUpdateTable(data) {
			this.tabData.receiveStaffList = data
		},

		// handleSaveConfig() {
		//   this.$parent.$parent.handleSave();
		// },
	},
}
</script>
<style lang="scss" scoped>
.tab-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	.title {
		font-size: 15px;
		color: #000;
		line-height: 40px;
		margin-top: 32px;
		em {
			color: #999;
		}
	}
	::v-deep .btn-staff {
		min-width: 80px;
		height: 30px;
		padding: 0 16px;
		border-radius: 16px;
		span {
			font-size: 12px;
		}
	}
	.table {
		margin-top: 24px;
		flex: 1;
	}
	::v-deep .ops-btn {
		cursor: pointer;
		color: $base-color-theme;
	}
}
.el-row {
	& + .el-row {
		margin-top: 26px;
	}
	.el-col {
		display: flex;
		align-items: center;
		.label {
			color: #999;
			margin-right: 4px;
		}
		.el-form-item {
			max-width: 260px;
			margin-bottom: 0;
		}
	}
}
.mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	@include flex-center;
	.content {
		width: 400px;
		height: 400px;
		background: #fff;
		border-radius: 4px;
		padding: 20px;
		position: relative;
		.icon {
			font-size: 18px;
			position: absolute;
			right: 0;
			top: -28px;
			cursor: pointer;
		}
		.qrcode {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
